import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Image, Button, Text, UnorderedList, ListItem, useMediaQuery, IconButton, Divider, Link, Flex} from '@chakra-ui/react';
import { PosTostMessage, PosImageCropper,  PosFormButton} from "../index.js";
import * as Constants from "../../constants/Constants";
import ProductImportFileImage from "../../assets/images/solar_file-line-duotone.svg";
import { CloseIcon } from "@chakra-ui/icons";
import Colors from "../../assets/colors/Colors";
import AddIcon from "@mui/icons-material/Add";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import toolTip from "../../assets/images/tool_tip_hover_icon.svg";


const ImageUploadReorder = ({ onImagesChange, initialImages = [] }) => {
  const childRef = useRef();
  const [images, setImages] = useState(initialImages);
  const { addToast } = PosTostMessage();
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [isScreenSize1280] = useMediaQuery("(max-width: 1280px)");
  const [imageUploadedFlag, setImageUploadedFlag] = useState(0);
  const [base64OfUploadedImg, setBase64OfUploadedImg] = useState("");
  const [croppedImgDetails, setCroppedImgDetails] = useState({
    filename: "",
    filetype: "",
    filesize: "",
    base64: "",
  });
  const [oldImageBase64, setOldImageBase64] = useState("");
  const [newImageUploadedFlag, setNewImageUploadedFlag] = useState(0);


  useEffect(() => {
    // Update the images state when the initialImages prop changes
    setImages(initialImages);
    onImagesChange(initialImages); // Call onImagesChange with the initial images
  }, [initialImages, onImagesChange]);
  
  // Function to convert file to base64
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // This is the base64 string
      };
      reader.onerror = () => {
        reject('Failed to convert file to base64.');
      };
      reader.readAsDataURL(file);
    });
  };

  const onDrop = async (acceptedFiles) => {
    const newImages = [];
    
    for (const file of acceptedFiles) {
      if (file.size > 5 * 1024 * 1024) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
          alertDescription: Constants.SLIDE_SHOW_IMAGE_SIZE_VALIDATION,
        });
        continue;
      }
      
      const img = new window.Image();
      img.src = URL.createObjectURL(file);
  
      const loadImage = new Promise((resolve, reject) => {
        img.onload = async () => {
          const { width, height } = img;
          // if (width === 1920 && height === 1080) {
            const base64DataUrl = await fileToBase64(file); // Get base64 string
            const base64 = base64DataUrl.split(",")[1]; // Extract only the base64 part
            // Get file details
            const fileName = file.name; // File name
            const fileType = file.type; // File type
            const fileSize = file.size; // File size in bytes
            resolve({ 
              file, 
              preview: img.src, 
              base64, 
              fileName, 
              fileType, 
              fileSize 
            });          
          // } else {
          //   reject(`Image ${file.name} does not have the required resolution of 1920x1080.`);
          // }
        };
        img.onerror = () => {
          reject(`Failed to load file ${file.name}.`);
        };
      });
  
      try {
        const imageData = await loadImage;
        newImages.push(imageData);
      } catch (error) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
          alertDescription: error,
        });
      }
    }

    const uniqueImages = newImages.filter(newImage =>
      !images.some(existingImage => existingImage.file.name.includes(newImage.file.name))
    );

    if (images.length + uniqueImages.length > 5) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
        alertDescription: Constants.MAXIMUM_IMAGES_ERROR,
      });
      return;
    }

    if (uniqueImages.length < newImages.length) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
        alertDescription: Constants.EXIST_IMAGE_ERROR,
      });
      return;
    }

    setImages((prevImages) => {
      const updatedImages = [...prevImages, ...uniqueImages];
      onImagesChange(updatedImages); // Call onImagesChange with the updated images
      return updatedImages;
    });
    childRef.current.chooseImgIconClick();
    // addToast({
    //   alertStatus: Constants.TOAST_TYPE_SUCESS,
    //   alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
    //   alertDescription: Constants.IMAGE_SUCCESS_ADDED,
    // });
  };

  const handleRemove = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    onImagesChange(updatedImages); // Call onImagesChange with the updated images after removal
  };

  const onDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside, do nothing
    const reorderedImages = Array.from(images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);
    setImages(reorderedImages);
    onImagesChange(reorderedImages); // Call onImagesChange with the reordered images
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const handleCallback = (base64Details, fileDetails, imgUploadStatus) => {
    if (
      (null == base64Details || "" == base64Details) &&
      (null == fileDetails || "" == fileDetails) &&
      (null == imgUploadStatus || "" == imgUploadStatus)
    ) {      
      setCroppedImgDetails({
        filename: "",
        filetype: "",
        filesize: "",
        base64: "",
      });
      setBase64OfUploadedImg("");
      setImageUploadedFlag(0);
    } else {
      if (imgUploadStatus.newImgUploadFlag == 1) {
        const croppedImage = base64Details.toDataURL(); // Get the base64 of the cropped image
  
        // Create an image element
        const img = new window.Image();
        img.src = croppedImage;
  
        img.onload = () => {
          // Create a canvas to draw the image
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
  
          // Set the desired dimensions for the canvas
          const MAX_WIDTH = 800; // Desired width
          const MAX_HEIGHT = 405; // Desired height
          let width = img.width;
          let height = img.height;
  
          // Calculate the new dimensions
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
  
          // Set the canvas dimensions
          canvas.width = width;
          canvas.height = height;
  
          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0, width, height);
  
          // Convert the canvas to a base64 string with a quality setting
          const optimizedBase64 = canvas.toDataURL("image/jpeg", 0.7); // 0.7 is the quality (70%)
  
          // Update state with optimized base64 image
          setCroppedImgDetails((previousState) => ({
            ...previousState,
            filename: fileDetails["name"],
            filetype: fileDetails["type"],
            filesize: optimizedBase64.length, // This is an approximation of the size in bytes
            base64: optimizedBase64.split(",")[1], // Only keep the base64 part
          }));
  
          setBase64OfUploadedImg(optimizedBase64);
          setImageUploadedFlag(1);
          setNewImageUploadedFlag(1);
        };
  
        img.onerror = () => {
          console.error("Failed to load the cropped image.");
        };
      } else {
        setCroppedImgDetails((previousState) => ({
          ...previousState,
          filename: null,
          filetype: null,
          filesize: null,
          base64: base64Details.toDataURL().split(",")[1],
        }));
        setBase64OfUploadedImg(base64Details.toDataURL());
        setImageUploadedFlag(1);
        setNewImageUploadedFlag(0);
      }
    }
  };

  const base64ToBlob = (base64, type = 'image/png') => {
    const byteCharacters = atob(base64); // Decode base64 string
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  };
  const slideShowAddImage = () => {  
    const blob = base64ToBlob(croppedImgDetails.base64, croppedImgDetails.filetype);
      const file = new File([blob], croppedImgDetails.filename, { 
      type: croppedImgDetails.filetype,
      lastModified: Date.now()
    });
    onDrop([file]);
  };
  return (
    <>
    <Box display="flex" alignItems="center">
      <PosImageCropper
        ref={childRef}
        slideShow={1}
        parentCallback={handleCallback}
        canvasWidth="14.75rem"
        canvasHeight="14.75rem"
        cropperWidth="400"
        cropperHeight="225"
        cropperMinWidth="200"
        cropperMaxWidth="800"
        cropperMinHeight="112"
        cropperMaxHeight="450"
      />
      {/* {croppedImgDetails.filename != '' && croppedImgDetails.filename != null && 
      <Box ml={6}>
        <UnorderedList>
          <ListItem>
          <Text
            style={{
              fontSize: "xs",
              color: "#010048",
              fontWeight: 300,
              marginTop: 2.5,
            }}
          >
            Please use 1920 (width) X 1080 (height) resolution for the images.
          </Text>
          </ListItem>
          <ListItem mt={1}>
          <Text
            style={{
              fontSize: "xs",
              color: "#010048",
              fontWeight: 300,
              marginTop: 2.5,
            }}
          >
            You can only upload upto 5 images.
            </Text>
          </ListItem>
          <ListItem mt={1}>
          <Text
            style={{
              fontSize: "xs",
              color: "#010048",
              fontWeight: 300,
              marginTop: 2.5,
            }}
          >
            Allows only upto 5 MB per image size.
            </Text>
          </ListItem>
          <ListItem mt={1}>
          <Text
            style={{
              fontSize: "xs",
              color: "#010048",
              fontWeight: 300,
              marginTop: 2.5,
            }}
          >
            You can adjust sequence of the slide show images on customer display screen using the drag & drop feature.
            </Text>
          </ListItem>
        </UnorderedList>
      </Box>
    } */}
    </Box>
    {croppedImgDetails.filename != '' && croppedImgDetails.filename != null && 
        <Box mt={2}>
          {/* <IconButton
            onClick={slideShowAddImage}
            isRound={true}
            isDisabled={croppedImgDetails.filename === ''}
          >
            <AddIcon />
          </IconButton> */}
          {/* <Link
              mt={"0.1rem"}
              fontSize={"0.75rem"}
              fontStyle={"normal"}
              fontWeight={"500"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              color={Colors.posNavbarLink}
              onClick={slideShowAddImage}
            >
              Add image to the sequence
            </Link> */}
          <PosFormButton
            isDisabled={
              croppedImgDetails.filename==''
                ?
                  true
                : false
            }
            buttonsubmit={"Add Image"}
            // SubmitButton={true}
            onClick={slideShowAddImage}
          />
        </Box>
      }
    <Box>
      {/* <Box
        {...getRootProps()}
        border="2px dashed"
        borderColor="gray.300"
        p={10}
        borderRadius="md"
        textAlign="center"
        cursor="pointer"
        display="flex"         // Use flexbox for alignment
        flexDirection="column" // Stack items vertically
        justifyContent="center" // Center items vertically
        alignItems="center"    // Center items horizontally
      >
        <input {...getInputProps()} />
        <Image
          w={6}
          h={6}
          src={ProductImportFileImage}
          alt="CDS Image"
          mb={2} // Add margin below the image for spacing
        />
        <Text style={{
          fontSize: "xs",
          color: "#010048",
          fontWeight: 500,
        }}>Drag and Drop Images here or </Text>
        <span
          style={{
            display: "block",
            textAlign: "center",
            fontSize: "xs",
            color: "#5881FE",
            fontWeight: 300,
          }}
        >
          {" "}
          Choose file
        </span>
      </Box> */}
      { null == croppedImgDetails.filename || '' == croppedImgDetails.filename &&
      <UnorderedList mt={4}>
        <ListItem>
        <Text
          style={{
            fontSize: "xs",
            color: "#010048",
            fontWeight: 300,
            marginTop: 2.5,
          }}
        >
          Please use 1920 (width) X 1080 (height) resolution for the images.
        </Text>
        </ListItem>
        <ListItem mt={1}>
        <Text
          style={{
            fontSize: "xs",
            color: "#010048",
            fontWeight: 300,
            marginTop: 2.5,
          }}
        >
          You can only upload upto 5 images.
          </Text>
        </ListItem>
        <ListItem mt={1}>
        <Text
          style={{
            fontSize: "xs",
            color: "#010048",
            fontWeight: 300,
            marginTop: 2.5,
          }}
        >
          Allows only upto 5 MB per image size.
          </Text>
        </ListItem>
        {/* <ListItem mt={1}>
        <Text
          style={{
            fontSize: "xs",
            color: "#010048",
            fontWeight: 300,
            marginTop: 2.5,
          }}
        >
          You can adjust sequence of the slide show images on customer display screen using the drag & drop feature.
          </Text>
        </ListItem> */}
      </UnorderedList>
      }
      {
      images.length > 0 &&
      <>
      <Divider borderColor="#E6E6E6" mt={5} mb={5} />

      <Box mb={2}>
      <Flex flexDirection={"row"}>
        <Text
          style={{
            fontSize: "xs",
            color: "#010048",
            fontWeight: 500,
          }}
        >
          Images & Sequence
        </Text>
        <Tippy
            content="You can adjust the sequence of slideshow images using the drag-and-drop feature."
            interactive={true}
            maxWidth={350}
            placement="top"
            animation="fade"
            inertia={true}
            moveTransition="transform 0.2s ease-out"
            theme="tomato"
          >
            <Image
              src={toolTip}
              alt="Tooltip"
              ml={"0.3rem"}
            />
          </Tippy>
        </Flex>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction={isMobile ? "vertical" : "horizontal"}>
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              mt={4}
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              alignItems={isMobile ? "flex-start" : "center"}
              height={isMobile ? (images.length > 0 ? "700px" : "") : ""}
            >
              {images.map((image, index) => (
                <Draggable key={image.file.name} draggableId={`draggable-${image.file.name}`} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      mb={2}
                      position="relative"
                      borderWidth="1px"
                      borderRadius="md"
                      overflow="hidden"
                      width="200px"
                      display="flex"
                      flexDirection="column" // Set to column to stack image and text
                      alignItems="center" // Center align for the text
                      ml={isMobile ? 0 : 2} // Adjust margin for mobile view
                    >
                      <Image
                        src={image.preview}
                        objectFit="cover"
                        width="100%"
                        height="100%"
                      />
                      <Text fontSize="sm" mb={1} color="black" textAlign="center"> {/* Center the text */}
                        Slide Show Image {index + 1}
                      </Text>
                      <Button
                        position="absolute"
                        top={1}
                        right={1}
                        size="xs"
                        colorScheme="red"
                        bg="gray.300"
                        onClick={() => handleRemove(index)}
                      >
                        <CloseIcon color={Colors.posCancleButtonRed} />
                      </Button>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      </>
      }
    </Box>
    </>
  );
};

export default ImageUploadReorder;